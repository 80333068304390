import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingState } from 'src/types/loading-state';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./login.scss'],
})
export class ResetPasswordComponent {
  constructor(private fb: FormBuilder, private http: HttpClient, private route: ActivatedRoute, private router: Router) {}

  LoadingState = LoadingState;
  loadingState = LoadingState.SUCCESS

  resetPassword = this.fb.group({
    password: ['', [Validators.required]],
  });

  get password() {
    return this.resetPassword.get('password');
  }

  get token() {
    return this.route.snapshot.queryParamMap.get("token");
  }

  handleSubmit() {
    this.loadingState = LoadingState.LOADING;
    this.http.post("/agetoage/service/login/password-reset", {
      token: this.token,
      password: this.password.value,
    })
      .toPromise()
      .then(() => {
        this.router.navigate(["./login"], {
          replaceUrl: true,
          queryParams: { action: "password_reset" }
        })
      })
      .catch((e) => {
        this.loadingState = LoadingState.FAILED;
        console.error(e)
      })
  }
}
