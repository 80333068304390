import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Request } from './request';

@Injectable({
  providedIn: 'root',
})
export class AgeToAgeServiceService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) {}

  async createAccount(email: string, password: string) {
    return await this.http
      .post<any>(
        '/' + environment.serviceContextPath + '/createAccount',
        { email: email, password: password },
        this.httpOptions
      )
      .toPromise();
  }
  async login(email: string, password: string) {
    return await this.http
      .post<any>(
        '/' + environment.serviceContextPath + '/login',
        { email: email, password: password },
        this.httpOptions
      )
      .toPromise();
  }
  async isSessionValid(sessionId: string) {
    return await this.http
      .get<any>(
        '/' +
          environment.serviceContextPath +
          '/checkSession?sessionId=' +
          sessionId,
        this.httpOptions
      )
      .toPromise();
  }
  async saveRequest(request: Request) {
    return await this.http
      .post<any>(
        '/' + environment.serviceContextPath + '/saveRequest',
        request,
        this.httpOptions
      )
      .toPromise();
  }
  async getRequestorDetails() {
    return await this.http
      .get<any>(
        '/' + environment.serviceContextPath + '/getRequestorDetails',
        this.httpOptions
      )
      .toPromise();
  }
  async getRequest(id: string) {
    // console.log('getRequest id: ' + id);
    return await this.http
      .get<any>(
        '/' + environment.serviceContextPath + '/getRequest?id=' + id,
        this.httpOptions
      )
      .toPromise();
  }
  async getMyRegistrationRequests(email: string) {
    return await this.http
      .get<any>(
        '/' +
          environment.serviceContextPath +
          '/getMyRegistrationRequests?requestorEmail=' +
          email,
        this.httpOptions
      )
      .toPromise();
  }
  async removeRequest(id: string) {
    return await this.http
      .delete<any>(
        '/' + environment.serviceContextPath + '/removeRequest?id=' + id,
        this.httpOptions
      )
      .toPromise();
  }
  async updatePassword(email: string, password: string, code: string) {
    return await this.http
      .post<any>(
        '/' + environment.serviceContextPath + '/updatePassword',
        { email: email, password: password, code: code },
        this.httpOptions
      )
      .toPromise();
  }

  async sendPassCode(email: string) {
    return await this.http
      .get<any>(
        '/' + environment.serviceContextPath + '/sendOTP?email=' + email,
        this.httpOptions
      )
      .toPromise();
  }

  async getActiveSession(email: string) {
    return await this.http
      .get<any>(
        '/' +
          environment.serviceContextPath +
          '/getActiveSession?email=' +
          email,
        this.httpOptions
      )
      .toPromise();
  }
}
