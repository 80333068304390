import { Component, OnInit } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Router } from '@angular/router';
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class PdfViewerComponent implements OnInit {
  constructor(private router: Router) {}
  pdfName: string = 'Disclaimer';
  pdfSrc: string;

  ngOnInit(): void {
    let currentRoute = this.router.url;
    if (currentRoute.includes('faqs')) {
      this.pdfName = 'FAQs';
    } else if (currentRoute.includes('privacypolicy')) {
      this.pdfName = 'privacypolicy';
    }
    this.pdfSrc = `assets/pdfs/${this.pdfName}.pdf`;
  }
}
