<mat-form-field>
  <mat-label>Filter Requests</mat-label>
  <input
    matInput
    #filter
    (keyup)="applyFilter($event.target.value)"
    placeholder="Eg. San Jose"
    #input
  />
  <button
    mat-icon-button
    matSuffix
    aria-label="clear"
    *ngIf="filter.value"
    (click)="filter.value = ''; applyFilter('')"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

<div class="mat-elevation-z8">
  <table
    mat-table
    [dataSource]="dataSource"
    multiTemplateDataRows
    class="mat-elevation-z8"
    matSort
  >
    <ng-container
      matColumnDef="{{ column }}"
      *ngFor="let column of columnsToDisplay"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        bgcolor="#009688"
        text-align="center"
      >
        <font color="white" STYLE="text-transform:capitalize">{{
          column
        }}</font>
      </th>
      <ng-container
        *ngIf="column != 'requestActions' && column != 'localDateTime'"
      >
        <td mat-cell *matCellDef="let element">
          {{ element[column] }}
        </td></ng-container
      >
      <ng-container *ngIf="column == 'localDateTime'">
        <td mat-cell *matCellDef="let element">
          {{ element[column].split("T")[0] }}
        </td></ng-container
      >
      <ng-container *ngIf="column == 'requestActions'">
        <td mat-cell *matCellDef="let element" nowrap>
          &nbsp;&nbsp;&nbsp;<button
            mat-button
            color="primary"
            type="submit"
            (click)="editRegistration(element.requestId)"
          >
            Edit</button
          >&nbsp;&nbsp;&nbsp;<button
            mat-button
            color="primary"
            type="submit"
            #deleteButton{{row.id}}
            (click)="deleteRegistration(element.requestId)"
          >
            Delete
          </button>
        </td></ng-container
      >
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="columnsToDisplay.length"
      >
        <div
          class="example-element-detail"
          [@detailExpand]="
            element == expandedElement ? 'expanded' : 'collapsed'
          "
        >
          <ng-container *ngIf="!element.sameLocation || !element.needVolunteer">
            <div class="example-element-diagram">
              <div class="example-element-position">
                <ng-container *ngIf="!element.sameLocation">
                  <b> {{ element.elderly.elderlyFullName }} </b><br />
                  {{ element.elderly.elderlyAddress.typeOfResidence }} <br />
                  {{ element.elderly.elderlyAddress.addressLine1 }} <br />
                  {{ element.elderly.elderlyAddress.addressLine2 }} <br />
                  {{ element.elderly.elderlyAddress.city }} <br />
                  {{ element.elderly.elderlyAddress.state }}&nbsp;&nbsp;{{
                    element.elderly.elderlyAddress.postalCode
                  }}<br />
                  Instructions: {{ element.elderly.elderlyAddress.instructions
                  }}<br />
                  <br />
                </ng-container>
                <ng-container *ngIf="!element.needVolunteer">
                  <b>Dispatch Address</b><br />
                  {{ element.dispatchAddress.addressLine1 }} <br />
                  {{ element.dispatchAddress.addressLine2 }} <br />
                  {{ element.dispatchAddress.city }} <br />
                  {{ element.dispatchAddress.state }}&nbsp;&nbsp;{{
                    element.dispatchAddress.postalCode
                  }}<br />
                </ng-container>
              </div>
            </div>
          </ng-container>
          <div class="example-element-description">
            <b>Purpose of visit</b>
            <span class="example-element-description-attribution">
              -- Additional requirements --
            </span>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplay"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="example-detail-row"
    ></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
<br />
<!-- <div align="right">
  <a mat-raised-button color="primary" href="agetoage" target=""
    >Make a New Request</a
  >
</div> -->
<!--<div id="progressBarApproval" ng-show="showApprovalProgress"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div><br/>-->
