import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalData } from './globaldata';
import { AgeToAgeServiceService } from './age-to-age-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  value = 0;
  title = 'Age to Age Service Tool';

  constructor(
    private router: Router,
    private ageToAgeService: AgeToAgeServiceService
  ) {
    GlobalData.ageToAgeService = ageToAgeService;
    GlobalData.router = router;
  }

}
