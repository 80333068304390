<main>
  <a routerLink="../" class="back-link">
    <mat-icon aria-hidden="true">arrow_back</mat-icon>
    <span>Back</span>
  </a>
  <h1>Create account</h1>
  <form [formGroup]="createAccount" class="mt-16" (ngSubmit)="handleSubmit()">
    <mat-form-field class="w-full mt-16">
      <mat-label>Email</mat-label>
      <input matInput type="email" formControlName="email" />
      <mat-error *ngIf="email.errors?.email && !email.errors?.required">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="email.errors?.required">Email is required</mat-error>
    </mat-form-field>

    <mat-form-field class="w-full mt-16">
      <mat-label>Password</mat-label>
      <input matInput type="password" formControlName="password" />
      <mat-error *ngIf="password.errors?.required">
        A password is required
      </mat-error>
      <mat-error
        *ngIf="password.errors?.minlength || password.errors?.maxlength"
      >
        Password should be between 8 and 128 characters
      </mat-error>
    </mat-form-field>

    <div class="flex w-full">
      <mat-form-field class="w-full mt-16">
        <mat-label>First name</mat-label>
        <input matInput formControlName="firstName" />
        <mat-error *ngIf="firstName.errors?.required">
          First name is required
        </mat-error>
        <mat-error
          *ngIf="firstName.errors?.minlength || firstName.errors?.maxlength"
        >
          First name should be between 2 and 8 characters
        </mat-error>
        <mat-error *ngIf="firstName.errors?.pattern">
          First name should only contain letters
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-full mt-16">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" />
        <mat-error *ngIf="lastName.errors?.required">
          Last name is required
        </mat-error>
        <mat-error
          *ngIf="lastName.errors?.minlength || lastName.errors?.maxlength"
        >
          Last name should be between 2 and 32 characters
        </mat-error>
        <mat-error *ngIf="lastName.errors?.pattern">
          Last name should only contain letters
        </mat-error>
      </mat-form-field>
    </div>

    <!-- <mat-form-field class="w-full mt-16">
      <mat-label>Org</mat-label>
      <input matInput formControlName="org" />
      <mat-error *ngIf="org.errors?.required">Org is required</mat-error>
      <mat-error *ngIf="org.errors?.pattern">
        Org should only contain letters
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-full mt-16">
      <mat-label>Phone number</mat-label>
      <input matInput formControlName="phone" />
      <mat-error *ngIf="phone.errors?.required">Phone is required</mat-error>
      <mat-error *ngIf="phone.errors?.minlength || phone.errors?.maxlength">
        Phone should be between 5 and 15 numbers
      </mat-error>
    </mat-form-field> -->

    <re-captcha
      formControlName="captcha"
      data-action="create_account"
      class="mt-8"
    ></re-captcha>

    <div
      *ngIf="loadingState === LoadingState.FAILED"
      class="banner banner-error"
    >
      <p>There was an error submitting your request, please try again later.</p>
    </div>

    <div class="ml-auto mt-16">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
        diameter="32"
        *ngIf="loadingState === LoadingState.LOADING"
      ></mat-progress-spinner>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="
          !createAccount.valid || loadingState === LoadingState.LOADING
        "
        *ngIf="loadingState !== LoadingState.LOADING"
      >
        <span>Create account</span>
      </button>
    </div>
  </form>
</main>
