<mat-card class="mat-elevation-z8">
  <mat-card-header>
    <mat-card-title>Requestor / Caregiver</mat-card-title>
    <mat-card-subtitle>Open a new request</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="clientRequestFormGroup">
      <div formGroupName="requestor">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>First Name</mat-label>
          <input
            matInput
            formControlName="requestorFirstName"
            required
            type="text"
          />
        </mat-form-field>
        &nbsp;
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Last Name</mat-label>
          <input
            matInput
            formControlName="requestorLastName"
            required
            type="text"
          />
        </mat-form-field>
        &nbsp;
        <div formGroupName="requestorMail">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Email</mat-label>
            <input
              matInput
              formControlName="requestorEmail"
              required
              type="text"
            />
          </mat-form-field>
          &nbsp;

          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Confirm Email</mat-label>
            <input
              matInput
              formControlName="confirmRequestorEmail"
              required
              type="text"
            />
          </mat-form-field>
          <mat-error
            *ngIf="
              clientRequestFormGroup.controls.requestor.get(
                'requestorMail.requestorEmail'
              ).touched &&
              clientRequestFormGroup.controls.requestor.get('requestorMail')
                .errors?.noMatch
            "
          >
            Email Ids do not match!
          </mat-error>
        </div>
        &nbsp;
        <div formGroupName="requestorMobile">
          <table>
            <tr>
              <td>
                <mat-label>Mobile Number</mat-label>
                <ngx-intl-tel-input
                  [cssClass]="'custom'"
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.India"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  [separateDialCode]="separateDialCode"
                  name="phone"
                  formControlName="requestorPhone"
                >
                </ngx-intl-tel-input>
              </td>
              &nbsp;
              <td>
                <mat-label>Confirm Mobile Number</mat-label>
                <ngx-intl-tel-input
                  [cssClass]="'custom'"
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.India"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  [separateDialCode]="separateDialCode"
                  name="confirmPhone"
                  formControlName="confirmRequestorPhone"
                >
                </ngx-intl-tel-input>
              </td>
            </tr>
          </table>

          <mat-error
            *ngIf="
              clientRequestFormGroup.controls.requestor.get(
                'requestorMobile.confirmRequestorPhone'
              ).touched &&
              clientRequestFormGroup.controls.requestor.get('requestorMobile')
                .errors?.noMatch
            "
          >
            Mobile Numbers do not match! </mat-error
          >&nbsp;
        </div>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Does your elderly loved one need a volunteer?</mat-label>
        <mat-select
          #matVolunteerOption
          formControlName="needVolunteer"
          (selectionChange)="checkVolunteer(matVolunteerOption)"
        >
          <mat-option *ngFor="let choice of choices" [value]="choice.value">
            {{ choice.viewValue }}
          </mat-option>
        </mat-select> </mat-form-field
      >&nbsp;&nbsp;
      <mat-form-field appearance="outline">
        <mat-label
          >Do you want to dispatch a volunteer to your guest's
          location?</mat-label
        >
        <mat-select
          #matLocationOption
          formControlName="sameLocation"
          (selectionChange)="checkLocation(matLocationOption)"
        >
          <mat-option
            *ngFor="let dispatchChoice of dispatchChoices"
            [value]="dispatchChoice.value"
          >
            {{ dispatchChoice.viewValue }}
          </mat-option>
        </mat-select> </mat-form-field
      >&nbsp;&nbsp;
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label
          >On what day of the week would you like to have your visit?</mat-label
        >
        <!-- #docregion toggle -->
        <input
          formControlName="requestedDate"
          matInput
          [min]="minDate"
          [max]="maxDate"
          [matDatepicker]="picker"
          [matDatepickerFilter]="dateFilter"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <!-- #enddocregion toggle --> </mat-form-field
      >&nbsp;&nbsp;
      <mat-form-field appearance="outline">
        <mat-label>Visit Duration</mat-label>
        <mat-select formControlName="duration" #duration matNativeControl>
          <mat-option *ngFor="let dur of durs" [value]="dur.value">
            {{ dur.viewValue }}
          </mat-option>
        </mat-select> </mat-form-field
      ><br />
      <mat-form-field appearance="outline" *ngIf="duration.value == 30">
        <mat-label>Choose Timeslot</mat-label>
        <mat-select
          formControlName="timeslot"
          required
          placeholder="Select Timeslot"
        >
          <mat-option
            *ngFor="let halfHourSlot of halfHourSlots"
            [value]="halfHourSlot"
          >
            {{ halfHourSlot }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="duration.value == 60">
        <mat-label>Choose Timeslot</mat-label>
        <mat-select
          formControlName="timeslot"
          required
          placeholder="Select Timeslot"
        >
          <mat-option
            *ngFor="let fullHourSlot of fullHourSlots"
            [value]="fullHourSlot"
          >
            {{ fullHourSlot }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-card>Geo Location of Requestor {{lat}},{{lng}}</mat-card><br/> -->
      <br />

      <p>
        To complete the booking, you must provide responses to the following
        statements.
      </p>
      <formGroupName formGroupName="requestor">
        <mat-checkbox
          formControlName="requestorEighteenYears"
          [(indeterminate)]="indeterminate"
        >
          <i
            >Users of this service must be at least 18 years of age. By clicking
            this box, you confirm that you are at least 18 years of age.</i
          > </mat-checkbox
        ><br /><br />
        <mat-checkbox
          formControlName="requestorCareGiverPresence"
          [(indeterminate)]="indeterminate"
          [disabled]="!(matVolunteerOption.value || matLocationOption.value)"
        >
          <i
            >By clicking this box you confirm that a responsible adult or
            caregiver will be present with the Elderly at the time of the
            visit.</i
          >
        </mat-checkbox>
        <br /><br />
        <mat-checkbox
          formControlName="requestorPrivacyPolicyCheck"
          [(indeterminate)]="indeterminate"
          [disabled]="!(matVolunteerOption.value || matLocationOption.value)"
        >
          <i
            >By checking 'Accept' you are indicating that you have read and
            agreed to the above Privacy Policy.</i
          >
        </mat-checkbox>
        <br /><br />
        <mat-checkbox
          formControlName="requestorRequestGurantee"
          [(indeterminate)]="indeterminate"
          [disabled]="!(matVolunteerOption.value || matLocationOption.value)"
        >
          <i
            >I understand this Age To Age visit cannot be guaranteed. However,
            Noble Strength Foundation will do its best to accommodate my
            request, and <br />will contact me immediately if they are unable to
            locate a volunteer at my requested date, time, and location(s)</i
          >
        </mat-checkbox>
        <br /><br />
        <mat-checkbox
          formControlName="requestorDisclaimerCheck"
          [(indeterminate)]="indeterminate"
          [disabled]="!(matVolunteerOption.value || matLocationOption.value)"
        >
          <i>Disclaimer opt in</i>
        </mat-checkbox>
        <br /><br />
        <mat-radio-group formControlName="requestorInternetConnection" required>
          <mat-label
            >The Requester’s location has a good internet connection</mat-label
          >
          <mat-radio-button class="radio-button" [value]="true"
            >Yes</mat-radio-button
          >
          <mat-radio-button class="radio-button" [value]="false"
            >No</mat-radio-button
          >
        </mat-radio-group>

        <br /><br />

        <mat-radio-group
          formControlName="requestorGuestInternetConnection"
          required
        >
          <mat-label
            >The Guest’s location has a good internet connection</mat-label
          >
          <mat-radio-button class="radio-button" [value]="true"
            >Yes</mat-radio-button
          >
          <mat-radio-button class="radio-button" [value]="false"
            >No</mat-radio-button
          >
        </mat-radio-group>
        <br /><br />
        <mat-checkbox
          formControlName="requestorVeteran"
          [(indeterminate)]="indeterminate"
          [disabled]="!(matVolunteerOption.value || matLocationOption.value)"
        >
          <i
            matTooltip="Click here if your senior and/or their guest is a Veteran; Military Spouse; or Military Brat. We would love to dispatch a fellow Vet to serve you."
            matTooltipPosition="right"
            matTooltipClass="custom-tooltip"
            >Veteran
          </i>
        </mat-checkbox>
        <br /><br />
      </formGroupName>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-raised-button
      type="submit"
      (click)="submitRequest()"
      [disabled]="
        !validationsBeforeSubmit(
          matVolunteerOption.value,
          matLocationOption.value
        )
      "
      *ngIf="route.snapshot.paramMap.get('id') == null"
    >
      Submit Request
    </button>
    <button
      mat-raised-button
      type="submit"
      (click)="submitRequest()"
      [disabled]="
        !validationsBeforeSubmit(
          matVolunteerOption.value,
          matLocationOption.value
        )
      "
      *ngIf="route.snapshot.paramMap.get('id') != null"
    >
      Update Request
    </button>
    <button mat-raised-button (click)="clientRequestFormGroup.reset()">
      Reset
    </button>
  </mat-card-actions>
</mat-card>
