import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingState } from 'src/types/loading-state';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.scss'],
})
export class LoginComponent {
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  LoadingState = LoadingState;
  loadingState = LoadingState.SUCCESS;

  login = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', [Validators.required]],
    captcha: ['', [Validators.required]],
  });

  get email() {
    return this.login.get('email');
  }

  get password() {
    return this.login.get('password');
  }

  get captcha() {
    return this.login.get('captcha');
  }

  get action() {
    return this.route.snapshot.queryParamMap.get('action');
  }

  handleSubmit() {
    this.loadingState = LoadingState.LOADING;
    const fd = new FormData();

    fd.append('username', this.email.value);
    fd.append('password', this.password.value);
    fd.append('captcha', this.captcha.value);

    this.http
      .post(`/agetoage/service/login`, fd)
      .toPromise()
      .then(() => {
        this.router.navigate(['/home'], {
          replaceUrl: true,
        });
      })
      .catch((e) => {
        this.loadingState = LoadingState.FAILED;
        this.password.setValue('');
        this.password.setErrors({ wrongPassword: true });
        console.error(e);
      });
  }
}
