<h1 mat-dialog-title>Elderly Loved One’s Address / Location</h1>
<div mat-dialog-content>
  <form [formGroup]="data">
    <div formGroupName="elderly">
      <mat-form-field appearance="outline" [style.width.%]="55">
        <mat-label>Full Name of the Elderly person</mat-label>
        <input
          matInput
          formControlName="elderlyFullName"
          required
          type="text"
        /> </mat-form-field
      >&nbsp;&nbsp;&nbsp;

      <div formGroupName="elderlyAddress">
        <!-- Type of Residence -->
        <mat-form-field appearance="outline" [style.width.%]="43">
          <mat-label>Type of Residence</mat-label>
          <mat-select matNativeControl formControlName="typeOfResidence">
            <mat-option value="Private Residence">
              Private Residence
            </mat-option>
            <mat-option value="Senior Living Facility">
              Senior Living Facility
            </mat-option>
            <mat-option value="Hospice (In-Home)">
              Hospice (In-Home)
            </mat-option>
            <mat-option value="Hospice (Facility)">
              Hospice (Facility)
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Address -->
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Address</mat-label>
          <textarea
            matInput
            formControlName="addressLine1"
            placeholder="Ex. 100 Main St"
          ></textarea>
        </mat-form-field>

        <!-- Address 2 -->
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Address 2</mat-label>
          <textarea matInput formControlName="addressLine2"></textarea>
        </mat-form-field>

        <!-- City State Postal Code -->

        <table class="example-full-width" cellspacing="0">
          <tr>
            <td>
              <!-- City -->
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>City</mat-label>
                <input
                  matInput
                  formControlName="city"
                  placeholder="Ex. San Francisco"
                />
              </mat-form-field>
            </td>
            <td>
              <!-- State -->
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>State</mat-label>
                <input
                  matInput
                  formControlName="state"
                  placeholder="Ex. California"
                />
              </mat-form-field>
            </td>
            <td>
              <!-- Postal Code -->
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Postal Code</mat-label>
                <input
                  matInput
                  formControlName="postalCode"
                  #postalCode
                  maxlength="10"
                  placeholder="Ex. 94105"
                  value=""
                />
                <mat-hint align="end">{{postalCode.value.length}} / 6</mat-hint>
              </mat-form-field>
            </td>
          </tr>
        </table>

        <!-- Special Instructions -->
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label
            >Are there any special entry instructions for the volunteer?
          </mat-label>
          <input
            matInput
            formControlName="instructions"
            #instructions
            maxlength="50"
            value=""
          />
          <mat-hint align="end">{{instructions.value.length}} / 50</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close] cdkFocusInitial>OK</button>
</div>
