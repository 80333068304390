import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent {
  navLinks = [
    {
      label: 'Home',
      link: './home',
      index: 0,
    },
    {
      label: 'My Requests',
      link: './requests',
      index: 1,
    },
    {
      label: 'Disclaimer',
      link: './disclaimer',
      index: 2,
    },
    {
      label: 'FAQs',
      link: './faqs',
      index: 3,
    },
    {
      label: 'Privacy Policy',
      link: './privacypolicy',
      index: 4,
    },
  ];

  constructor() {}
}
