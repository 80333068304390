import { Elderly } from './elderly';
import { Requestor } from './requestor';
import { Address } from './address';

export class Request {
  requestId: number;
  requestor: Requestor;
  elderly: Elderly;
  relationship: string;
  duration: number;
  timeslot: string;
  requestStatus: string;
  dispatchAddress: Address;
  sameLocation: boolean;
  needVolunteer: boolean;
  localDateTime: string;
}
