<!--  <ng-container *ngIf="link.label == 'Make Budget Request'">
        <a mat-tab-link
        [routerLink]="link.link"
        routerLinkActive #rla="routerLinkActive"
        [active]="rla.isActive">{{link.label}}
      </a>
    </ng-container>-->

<nav mat-tab-nav-bar>
  <ng-container *ngFor="let link of navLinks">
    <a
      mat-tab-link
      [routerLink]="link.link"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
      ><mat-button color="primary">{{ link.label }}</mat-button>
    </a>
  </ng-container>
</nav>

<router-outlet></router-outlet>
