<main>
  <h1>Login</h1>
  <div *ngIf="action === 'account_created'" class="banner">
    <p>We've created your account, check your email for an activation link</p>
  </div>
  <div *ngIf="action === 'password_reset_request'" class="banner">
    <p>
      We've got your password reset request, check your email to get started
    </p>
  </div>
  <div *ngIf="action === 'password_reset'" class="banner">
    <p>We've reset your password, login below to get started</p>
  </div>
  <div *ngIf="action === 'account_activated'" class="banner">
    <p>We've activated your account, login below to get started</p>
  </div>
  <div *ngIf="action === 'account_activation_failed'" class="banner">
    <p>We couldn't activate your account</p>
    <p>
      The token could not be found, might have expired or has been invalidated
    </p>
    <p>You can request a new token <a routerLink="./activate">here</a></p>
  </div>
  <div *ngIf="action === 'resend_activation_email'" class="banner">
    <p>
      We've got your account activation request, check your email to get started
    </p>
  </div>
  <form [formGroup]="login" class="mt-16" (ngSubmit)="handleSubmit()">
    <mat-form-field class="w-full mt-16">
      <mat-label>Email</mat-label>
      <input matInput type="email" formControlName="email" />
      <mat-error *ngIf="email.errors?.email && !email.errors?.required">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="email.errors?.required">Email is required</mat-error>
    </mat-form-field>

    <mat-form-field class="w-full mt-16">
      <mat-label>Password</mat-label>
      <input matInput type="password" formControlName="password" />
      <mat-error *ngIf="password.errors?.required">
        A password is required
      </mat-error>
      <mat-error *ngIf="password.errors?.wrongPassword">
        Wrong email or password, try again or reset it
      </mat-error>
    </mat-form-field>

    <re-captcha
      formControlName="captcha"
      data-action="login"
      class="mt-8"
    ></re-captcha>

    <a routerLink="./forgot-password" class="my-8">Forgotton password?</a>

    <div id="login-actions">
      <a color="primary" routerLink="./create-account">Create account</a>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="!login.valid || loadingState === LoadingState.LOADING"
      >
        Login
      </button>
    </div>
  </form>
</main>
