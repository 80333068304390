import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { LoadingState } from 'src/types/loading-state';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./login.scss'],
})
export class CreateAccountComponent {
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private r: Router
  ) {}

  LoadingState = LoadingState;
  loadingState = LoadingState.IDLE;

  createAccount = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
    password: [
      '',
      [Validators.required, Validators.minLength(8), Validators.maxLength(128)],
    ],
    firstName: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(16),
        Validators.pattern('^[a-zA-Z ]+$'),
      ],
    ],
    lastName: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(16),
        Validators.pattern('^[a-zA-Z ]+$'),
      ],
    ],
    captcha: ['', [Validators.required]],
    // org: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]],
    // phone: [
    //   '',
    //   [Validators.required, Validators.minLength(5), Validators.maxLength(15)],
    // ],
  });

  get email() {
    return this.createAccount.get('email');
  }

  get password() {
    return this.createAccount.get('password');
  }

  get firstName() {
    return this.createAccount.get('firstName');
  }

  get lastName() {
    return this.createAccount.get('lastName');
  }

  get captcha() {
    return this.createAccount.get('captcha');
  }

  // get org() {
  //   return this.createAccount.get('org');
  // }

  // get phone() {
  //   return this.createAccount.get('phone');
  // }

  handleSubmit() {
    this.loadingState = LoadingState.LOADING;
    this.http
      .post(`/agetoage/service/login/create-account`, {
        email: this.email.value,
        password: this.password.value,
        first_name: this.firstName.value,
        last_name: this.lastName.value,
        captcha: this.captcha.value,
        // org: this.org.value,
        // phone: this.phone.value,
      })
      .toPromise()
      .then(() => {
        this.r.navigate(['./login'], {
          replaceUrl: true,
          queryParams: { action: 'account_created' },
        });
      })
      .catch(() => {
        this.loadingState = LoadingState.FAILED;
      });
  }
}
