import { Address } from './address';

export class Requestor {
  id: string;
  requestorEmail: string;
  requestorFirstName: string;
  requestorLastName: string;
  requestorPhone: string;
  requestorAddress: Address;
  requestorEighteenYears: boolean;
  requestorInternetConnection: boolean;
  requestorGuestInternetConnection: boolean;
  requestorCareGiverPresence: boolean;
  requestorPrivacyPolicyCheck: boolean;
  requestorRequestGurantee: boolean;
  requestorDisclaimerCheck: boolean;
  requestorVeteran: boolean;
}
