<main class="">
  <h1>Password reset</h1>
  <p>Enter your new password</p>
  <form [formGroup]="resetPassword" class="mt-16" (ngSubmit)="handleSubmit()">
    <mat-form-field class="w-full mt-16">
      <mat-label>New password</mat-label>
      <input matInput type="password" formControlName="password" />
      <mat-error *ngIf="password.errors?.required">
        A new password is required</mat-error
      >
    </mat-form-field>

    <div
      *ngIf="loadingState === LoadingState.FAILED"
      class="banner banner-error"
    >
      <p>There was an error submitting your request, please try again later.</p>
    </div>

    <div class="ml-auto mt-16">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
        diameter="32"
        *ngIf="loadingState === LoadingState.LOADING"
      ></mat-progress-spinner>
      <button
        mat-flat-button
        color="primary"
        [disabled]="
          !forgotPassword.valid || loadingState === LoadingState.LOADING
        "
        *ngIf="loadingState !== LoadingState.LOADING"
      >
        Reset password
      </button>
    </div>
  </form>
</main>
