<main>
  <a routerLink="../" class="back-link">
    <mat-icon aria-hidden="true">arrow_back</mat-icon>
    <span>Back</span>
  </a>
  <h1>Activate you account</h1>
  <p>
    Enter your email below and we'll send you a link to activate your account
  </p>

  <form [formGroup]="activate" class="mt-16" (ngSubmit)="handleSubmit()">
    <mat-form-field class="w-full mt-16">
      <mat-label>Email</mat-label>
      <input matInput type="email" formControlName="email" />
      <mat-error *ngIf="email.errors?.email && !email.errors?.required">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="email.errors?.required">Email is required</mat-error>
    </mat-form-field>

    <re-captcha
      formControlName="captcha"
      data-action="activate_account"
      class="mt-8"
    ></re-captcha>

    <div
      *ngIf="loadingState === LoadingState.FAILED"
      class="banner banner-error"
    >
      <p>There was an error submitting your request, please try again later.</p>
    </div>

    <div class="ml-auto mt-16">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
        diameter="32"
        *ngIf="loadingState === LoadingState.LOADING"
      ></mat-progress-spinner>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!activate.valid || loadingState === LoadingState.LOADING"
        *ngIf="loadingState !== LoadingState.LOADING"
      >
        Send email
      </button>
    </div>
  </form>
</main>
