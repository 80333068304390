import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AgeToAgeServiceService } from '../age-to-age-service.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Request } from '../request';
import { AppComponent } from '../app.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class RequestsComponent implements AfterViewInit {
  columnsToDisplay: string[] = [
    'requestStatus',
    'duration',
    'timeslot',
    'needVolunteer',
    'localDateTime',
    'requestActions',
  ];
  dataSource: MatTableDataSource<Request>;
  showRequestProgress = true;
  expandedElement: Request | null;
  registrationRequests = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
    private _snackBar: MatSnackBar,
    private ageToAgeService: AgeToAgeServiceService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.getMyRegistrationRequests();
  }

  async deleteRegistration(id: string) {
    let response = await this.ageToAgeService.removeRequest(id);
    this._snackBar.open(response.response, 'OK', {
      duration: 50000,
    });
    this.getMyRegistrationRequests();

    //  document.getElementById("deleteButton"+id).disabled = true;
  }

  async editRegistration(id: string) {
    this.router.navigate(['/home/' + id]);
  }

  async getMyRegistrationRequests() {
    this.registrationRequests = [];
    let response = await this.ageToAgeService.getMyRegistrationRequests(
      localStorage.getItem('email')
    );
    for (let entry of response) {
      this.registrationRequests.push(entry);
    }

    const rr = this.registrationRequests;
    // console.log(rr);
    this.dataSource = new MatTableDataSource<Request>(rr);
    this.dataSource.filterPredicate = (data: any, filter) => {
      const dataStr = JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) != -1;
    };
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    //  document.getElementById("progressBarRequest").hidden = true;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  applyFilter(filterValue: string) {
    // filterValue = filterValue.trim(); // Remove whitespace
    // filterValue = filterValue.toLowerCase(); // MatTableDataSource
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
