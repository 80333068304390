import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { GlobalData } from '../globaldata';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgeToAgeServiceService } from '../age-to-age-service.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Request } from '../request';
import { Requestor } from '../requestor';

import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';

export interface DialogData {}

interface DispatchChoice {
  value: any;
  viewValue: string;
}

interface Select {
  value: any;
  viewValue: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  indeterminate = false;
  minDate: Date;
  maxDate: Date;
  public lat = -1;
  public lng = -1;
  title = 'Age to Age';
  clientRequestFormGroup: FormGroup;
  dispatchChoices: DispatchChoice[] = [
    { value: true, viewValue: 'Yes' },
    { value: false, viewValue: 'No' },
  ];

  halfHourSlots: string[] = [
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
  ];
  fullHourSlots: string[] = [
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
  ];
  choices: Select[] = [
    { value: true, viewValue: 'Yes' },
    { value: false, viewValue: 'No' },
  ];
  durs: Select[] = [
    { value: 30, viewValue: '30 Mins' },
    { value: 60, viewValue: '60 Mins' },
  ];
  datePipe: DatePipe = new DatePipe('en-US');
  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private http: HttpClient,
    private ageToAgeService: AgeToAgeServiceService,
    private router: Router,
    public route: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setMonth(this.maxDate.getMonth() + 11);
    GlobalData.ageToAgeService = ageToAgeService;
    GlobalData.router = router;
  }

  // async getRequestorDetails() {
  //   var response = await this.ageToAgeService.getRequestorDetails();
  //   this.clientRequestFormGroup.controls.requestor.patchValue(response);
  //   this.clientRequestFormGroup.controls.requestor
  //     .get('requestorMobile.requestorPhone')
  //     .patchValue(response.requestorPhone);
  // }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  async submitRequest() {
    var requestObject: Request;
    requestObject = new Request();
    requestObject = this.clientRequestFormGroup.value;
    const s = this.route.snapshot.paramMap.get('id');
    requestObject.requestId = Number(this.route.snapshot.paramMap.get('id'));
    requestObject.requestStatus = 'open';
    requestObject.requestor.requestorEmail =
      this.clientRequestFormGroup.controls.requestor.get(
        'requestorMail.requestorEmail'
      ).value;
    requestObject.requestor.requestorPhone =
      this.clientRequestFormGroup.controls.requestor.get(
        'requestorMobile.requestorPhone'
      ).value.e164Number;
    requestObject.localDateTime =
      this.datePipe.transform(
        this.clientRequestFormGroup.value.requestedDate,
        'yyyy-MM-dd'
      ) +
      'T' +
      this.clientRequestFormGroup.get('timeslot').value +
      ':00';

    this.clientRequestFormGroup.markAllAsTouched();
    // console.log(this.clientRequestFormGroup);
    if (this.clientRequestFormGroup.valid) {
      var response = await this.ageToAgeService.saveRequest(requestObject);
      this.router.navigateByUrl('requests');
      alert('Your request has been submitted');
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(Dialog, {
      width: '250px',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  openElderlyAddressDialog(): void {
    const dialogRef = this.dialog.open(ElderlyAddressDialog, {
      width: '60%',
      data: this.clientRequestFormGroup,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog Elderly Address was closed');
    });
  }

  openDispatchAddressDialog(): void {
    const dialogRef = this.dialog.open(DispatchAddressDialog, {
      width: '60%',
      data: this.clientRequestFormGroup,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog dispatch was closed');
    });
  }

  checkLocation(data: any) {
    if (data.value == true) this.openDispatchAddressDialog();
  }

  checkVolunteer(data: any) {
    if (data.value == true) this.openElderlyAddressDialog();
  }
  dateFilter = (date: Date) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0); // Normalize the time to compare just the dates
    const threeDaysFromNow = new Date(now);
    threeDaysFromNow.setDate(threeDaysFromNow.getDate() + 4);
    return date < now || date >= threeDaysFromNow;
  };

  validationsBeforeSubmit(
    matVolunteerOption: boolean,
    matLocationOption: boolean
  ) {
    var requestorObject: Requestor;
    // console.log('inside submit request');
    requestorObject = new Requestor();
    requestorObject = this.clientRequestFormGroup.get('requestor').value;
    // console.log(requestorObject);
    return (
      ((requestorObject.requestorEighteenYears &&
        requestorObject.requestorCareGiverPresence &&
        requestorObject.requestorPrivacyPolicyCheck &&
        matVolunteerOption &&
        requestorObject.requestorRequestGurantee) ||
        (requestorObject.requestorEighteenYears &&
          matLocationOption &&
          requestorObject.requestorCareGiverPresence &&
          requestorObject.requestorPrivacyPolicyCheck &&
          requestorObject.requestorRequestGurantee)) &&
      requestorObject.requestorInternetConnection !== null &&
      requestorObject.requestorGuestInternetConnection !== null
    );
  }

  async getRequestDetails(id: string) {
    var response = await this.ageToAgeService.getRequest(id);
    // console.log(response);

    if (!response) {
      alert('Not authorized to view data.');
      this.router.navigateByUrl('requests');
      return;
    }
    this.clientRequestFormGroup.patchValue(response);
    this.clientRequestFormGroup.controls.requestedDate.setValue(
      new Date(
        this.datePipe.transform(
          response.localDateTime.split('T')[0],
          'MM/dd/yyyy'
        )
      )
    );

    console.log(response.requestor);
    this.clientRequestFormGroup.patchValue({
      requestor: {
        requestorMail: {
          requestorEmail: response.requestor.requestorEmail,
          confirmRequestorEmail: response.requestor.requestorEmail,
        },
        requestorMobile: {
          requestorPhone: response.requestor.requestorPhone,
          confirmRequestorPhone: response.requestor.requestorPhone,
        },
      },
    });
    // console.log(this.clientRequestFormGroup.controls.requestor);
  }

  ngAfterViewInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    if (id == null) {
      // this.getRequestorDetails();
      // request details will need to be entered by user
    } else {
      this.getRequestDetails(id);
    }
  }

  ngOnInit(): void {
    // this.getLocation();

    //-	My street / city / country / building-apartment / zip code / date time / timezone / (Do you have internet connection at that location ?
    this.clientRequestFormGroup = this._formBuilder.group({
      sameLocation: new FormControl('', Validators.required),
      needVolunteer: new FormControl('', Validators.required),
      requestedDate: new FormControl('', Validators.required),
      duration: new FormControl('', Validators.required),
      timeslot: new FormControl('', Validators.required),
      guest: new FormGroup({
        guestFullName: new FormControl(''),
        guestAddress: new FormGroup({
          typeOfResidence: new FormControl(''),
          addressLine1: new FormControl(''),
          addressLine2: new FormControl(''),
          city: new FormControl(''),
          state: new FormControl(''),
          postalCode: new FormControl(''),
          instructions: new FormControl(''),
        }),
      }),

      requestor: new FormGroup({
        requestorFirstName: new FormControl('', Validators.required),
        requestorLastName: new FormControl('', Validators.required),
        requestorEighteenYears: new FormControl(false, Validators.required),
        requestorMail: new FormGroup(
          {
            requestorEmail: new FormControl('', Validators.required),
            confirmRequestorEmail: new FormControl('', Validators.required),
          },
          { validators: [this.confirmMailEquals()] }
        ),

        requestorInternetConnection: new FormControl(null, Validators.required),
        requestorGuestInternetConnection: new FormControl(
          null,
          Validators.required
        ),
        requestorCareGiverPresence: new FormControl(false, Validators.required),
        requestorPrivacyPolicyCheck: new FormControl(
          false,
          Validators.required
        ),
        requestorRequestGurantee: new FormControl(false, Validators.required),
        requestorDisclaimerCheck: new FormControl(false),
        requestorVeteran: new FormControl(false),
        requestorMobile: new FormGroup(
          {
            requestorPhone: new FormControl('', Validators.required),
            confirmRequestorPhone: new FormControl('', Validators.required),
          },
          { validators: [this.confirmMobileEquals()] }
        ),
      }),

      elderly: new FormGroup({
        elderlyFullName: new FormControl(''),
        elderlyAddress: new FormGroup({
          typeOfResidence: new FormControl(''),
          addressLine1: new FormControl(''),
          addressLine2: new FormControl(''),
          city: new FormControl(''),
          state: new FormControl(''),
          postalCode: new FormControl(''),
          instructions: new FormControl(''),
        }),
      }),
    });
    this.clientRequestFormGroup
      .get('sameLocation')
      .valueChanges.subscribe((value) => {
        const guestFullNameControl = this.clientRequestFormGroup
          .get('guest')
          .get('guestFullName');

        if (value === 'yes') {
          guestFullNameControl.setValidators([Validators.required]);
        } else {
          guestFullNameControl.clearValidators();
        }

        guestFullNameControl.updateValueAndValidity();
      });
    this.clientRequestFormGroup
      .get('needVolunteer')
      .valueChanges.subscribe((value) => {
        const elderlyFullNameControl = this.clientRequestFormGroup
          .get('elderly')
          .get('elderlyFullName');

        if (value === 'yes') {
          elderlyFullNameControl.setValidators([Validators.required]);
        } else {
          elderlyFullNameControl.clearValidators();
        }

        elderlyFullNameControl.updateValueAndValidity();
      });
  }
  confirmMobileEquals(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      control.get('requestorPhone').value.e164Number ===
      control.get('confirmRequestorPhone').value.e164Number
        ? null
        : { noMatch: true };
  }

  confirmMailEquals(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      control.get('requestorEmail').value ===
      control.get('confirmRequestorEmail').value
        ? null
        : { noMatch: true };
  }
}

@Component({
  selector: 'dialog',
  templateUrl: 'dialog.html',
})
export class Dialog {
  constructor(
    public dialogRef: MatDialogRef<Dialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

//elderly address
@Component({
  selector: 'elderly-address-dialog',
  templateUrl: 'elderly-address-dialog.html',
  styleUrls: ['./address.scss'],
})
export class ElderlyAddressDialog {
  constructor(
    public dialogRef: MatDialogRef<ElderlyAddressDialog>,
    @Inject(MAT_DIALOG_DATA) public data: FormGroup
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

//guest address
@Component({
  selector: 'dispatch-address-dialog',
  templateUrl: 'dispatch-address-dialog.html',
  styleUrls: ['./address.scss'],
})
export class DispatchAddressDialog {
  constructor(
    public dialogRef: MatDialogRef<DispatchAddressDialog>,
    @Inject(MAT_DIALOG_DATA) public data: FormGroup
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
