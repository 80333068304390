import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './material-module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {
  RecaptchaModule,
  RecaptchaFormsModule,
  RecaptchaSettings,
  RECAPTCHA_SETTINGS,
} from 'ng-recaptcha';

import {
  Dialog,
  ElderlyAddressDialog,
  DispatchAddressDialog,
} from './home/home.component';
import { HomeComponent } from './home/home.component';
import { RequestsComponent } from './requests/requests.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { LoginComponent } from './login/login.component';
import { CreateAccountComponent } from './login/create-account.component';
import { ForgotPasswordComponent } from './login/forgot-password.component';
import { ResetPasswordComponent } from './login/reset-password.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { httpInterceptorProviders } from './auth/interceptor';
import { AccountActivationComponent } from './login/account-activation.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './display-pdfs/pdfviewer.component';

@NgModule({
  declarations: [
    AppComponent,
    Dialog,
    ElderlyAddressDialog,
    DispatchAddressDialog,
    HomeComponent,
    RequestsComponent,
    LoginComponent,
    MainLayoutComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CreateAccountComponent,
    AccountActivationComponent,
    PdfViewerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    DemoMaterialModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    PdfViewerModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  entryComponents: [Dialog, ElderlyAddressDialog, DispatchAddressDialog],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LdMfWEqAAAAANbt1g4qGB0lVVKrL11jfR5IhCSJ',
      } as RecaptchaSettings,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
